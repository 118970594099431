const initWidgetInfo = [
  {
    title: "Release TK49",
    vnr: "2.6.2",
    info: "Naprawiono błąd podczas dodawania i edycji wpisów. Od teraz miejsce pracy oraz godziny pracy nie powinny znikać. Należy być ostrożnym podczas kopiowania starych wpisów dotyczących drobnych projektów. Mogą one powodowac błędy w Timerze.",
  },
  {
    title: "Release TK49",
    vnr: "2.6.1",
    info: "Naprawiono nadpisywanie miejsca pracy i godzin pracy podczas edycji wpisu. W panelu admin naprawiono skompresowany widok plamu pracy ekspertów.",
  },
  {
    title: "Release TK48",
    vnr: "2.6.0",
    info: "Opcja 'Hour management' dostępna w oknie INT oraz Logistics. Od teraz osoby zajmujące się zarządzaniem modułami mogą dodawac godziny w tych taskach.",
  },
  {
    title: "Release TK40",
    vnr: "2.5.9",
    info: "Naprawiono błędne wyświetlanie miesiąca w productivity teams. Teraz dane przedstawiane są prawidłowo dla poprzedniego miesiąca. Naprawiono rok obrotowy w tych raportach. Dane przedstawiane w tabeli są od Marca br do Marca kolejnego roku.",
  },
  {
    title: "Release TK32",
    vnr: "2.5.7",
    info: "Naprawiono opcję dodawania modułów w panelu admin. Od teraz można dodawać moduły w ścieżce Countries/Other.",
  },
];

const widgetReducer = (state = initWidgetInfo, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default widgetReducer;