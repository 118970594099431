import React from "react";

const ProductivityINT = (props) => {
  const properties = props.properties;

  return (
    <>
      <tr>
        <th>INT</th>
      </tr>
      <tr>
        <th className="int" style={properties.styles.int}>
          {" "}
          FtF 2.0
        </th>
        <td className="int" style={properties.styles.int}>
          {" "}
          21000393
        </td>
        <td className="int" style={properties.styles.int}>
          {properties.intFtf20Sum.toString().replace(".", ",")}
        </td>
        <td className="int" style={properties.styles.int}>
          {properties.intFtf20Sum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((_, idx) => {
          return <td key={idx} className="blank" style={properties.styles.blank}></td>;
        })}
      </tr>
      <tr>
        <th className="int" style={properties.styles.int}>
          {" "}
          MA 3.0
        </th>
        <td className="int" style={properties.styles.int}></td>
        <td className="int" style={properties.styles.int}>
          {properties.intMa30Sum.toString().replace(".", ",")}
        </td>
        <td className="int" style={properties.styles.int}>
          {properties.intMa30Sum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((_, idx) => {
          return <td key={idx} className="blank" style={properties.styles.blank}></td>;
        })}
      </tr>
      <tr>
        <th className="int" style={properties.styles.int}>
          {" "}
          Nuggets
        </th>
        <td className="int" style={properties.styles.int}></td>
        <td className="int" style={properties.styles.int}>
          {properties.intNuggetsSum.toString().replace(".", ",")}
        </td>
        <td className="int" style={properties.styles.int}>
          {properties.intNuggetsSum.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((_, idx) => {
          return <td key={idx} className="blank" style={properties.styles.blank}></td>;
        })}
      </tr>
      <tr>
        <th className="int" style={properties.styles.int}>
          {" "}
          Localization management
        </th>
        <td className="int" style={properties.styles.int}></td>
        <td className="int" style={properties.styles.int}>
          {properties.locMan.toString().replace(".", ",")}
        </td>
        <td className="int" style={properties.styles.int}>
          {properties.locMan.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((_, idx) => {
          return <td key={idx} className="blank" style={properties.styles.blank}></td>;
        })}
      </tr>
      <tr>
        <th className="int" style={properties.styles.int}>
          {" "}
          Hour management
        </th>
        <td className="int" style={properties.styles.int}></td>
        <td className="int" style={properties.styles.int}>
          {properties.hourManINT.toString().replace(".", ",")}
        </td>
        <td className="int" style={properties.styles.int}>
          {properties.hourManINT.toString().replace(".", ",")}
        </td>
        <td className="blank" style={properties.styles.blank}></td>
        {properties.countries.map((_, idx) => {
          return <td key={idx} className="blank" style={properties.styles.blank}></td>;
        })}
      </tr>
      {properties.other.map((module, idx) => {
        return (
          <tr key={idx}>
            <th className="int" style={properties.styles.int}>
              {module.name}
            </th>
            <td className="int" style={properties.styles.int}></td>
            <td className="int" style={properties.styles.int}>
              {properties.intSingleModuleSum(module).toString().replace(".", ",")}
            </td>
            <td className="int" style={properties.styles.int}>
              {properties.intSingleModuleSum(module).toString().replace(".", ",")}
            </td>
            <td className="blank" style={properties.styles.blank}></td>
            {properties.countries.map((_, idx) => {
              return <td key={idx} className="blank" style={properties.styles.blank}></td>;
            })}
          </tr>
        );
      })}
    </>
  );
};

export default ProductivityINT;
